article.sld-list.sld-product-grid {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
	display: grid;
    grid-template-columns: 20% 50% 30%;
    @include media-breakpoint-down(md) {
        grid-template-columns: 15% 45% 40%;
    }
	.sld-buylater {
		display: flex;
		justify-content: center;
		flex: 1;
	}
	.js-mailalert,
	.alert-button {
		width: 100%;
	}
}

#product .sld-product,
article.sld-list.sld-product-grid{
    .sld-product-select {
			width: 100%;
			border: 1px solid #c7c7c7;
			color: #5d5d5d;
			font-size: 0.7rem;
			font-weight: bold;
			padding: 0.25rem 0;
			height: 34px;
		}
    .sld-stock {
			align-items: center;
			font-size: 0.75rem;
			line-height: 1;
			text-align: left;
			margin-bottom: 0.5rem;
            border-bottom: 1px solid $color_primary;
			span{
				line-height: 1rem;
			}
      /* ** ** */
      strong { float:right; }
      /* ** ** */
	}
    .shopping-list {
		width: 100%;
		margin: 0;
		position: inherit;
		max-width: 50px;
	}
	.sld-cart {
            justify-content: space-between;
			display: flex;
			align-items: self-start;
			padding: 0;
			position: relative;
            .slt-cart-btn {
				box-shadow: 0px 0px 0px 2px #5d5d5d;
				border-radius: 3px;
				transition: box-shadow 0.6s linear;
				margin: 0.5em;
				padding: 0.5rem 0;
				color: #3d3d3d;
				font-size: 0.9rem;
				line-height: 1;
				&:hover {
					box-shadow: 0px 0px 0px 2px #005ea8;
					color: #005ea8;
				}
			}
			.sld-demande-prix,
			.sld-add-to-cart-product,
			.js-ajax-add-to-cart{
				color: #fff;
				background-color: #005ea8;
				display: flex;
				align-items: center;
				justify-content: space-around;
				padding: .3125rem 0.8rem;
				height: 34px;
                margin-left: 15px;
                @include media-breakpoint-between(md, lg) {
                         padding: 0.25rem 0.3rem;
                        margin-left: 5px;
                    }
                
				&:hover{
					background-color: #20bc24;
				}
			}
            .sld-demande-prix {
                border-radius: inherit;
                box-shadow: none;
                padding: 0.4rem 0.5rem;
                /* ** ** */
                width: 100%;
                margin: 0;
                /* ** ** */
                &:hover {
                    box-shadow: none;
                    color: #fff;
                }
            }
			.sld-add-to-cart-product {
				color: #005ea8;
				background-color: #fff;
			}
			i {
				font-size: 1.5rem;
			}
			.sld-buylater {
				a {
					font-weight: normal;
					font-size: 0.7rem;
					i {
						font-size: 1rem;
					}
				}
				
				.mailalert-product-page{
				
					a{
						color:#fff;
						background-color:#005ea8;
						display: flex;
						align-items: center;
						justify-content: space-around;
            			padding: 0.4rem 0.8rem;
						border-radius: inherit;
					}			
				}
			}

		}
}
article.sld-list.sld-product-grid .shopping-list {
	@include media-breakpoint-down(sm) {
		margin-left: 15px;
	}
	.add-shopping-list {
		padding: 0 0.8rem;
		display: block;
		border: solid 1px #007AD1;
		line-height: 32px;
}
} 
#product .sld-product {
	.sld-product-grid-6 {
		position: relative;
	}
}
#product .sld-product,
article.sld-list.sld-product-grid {
	margin-bottom: 0.5rem;
	padding: 0.5rem;
	background-color: #fff;
	.grid-item {
		&.sld-product-grid-2 {
			height: 100%;
			display: flex;
			grid-template-rows: 50% 50%;
			justify-self: stretch;
			justify-content: flex-start;
			@include media-breakpoint-up(md) {
				padding: 0 1rem;
			}
			@include media-breakpoint-down(sm) {
				padding: 0;
			}
		}
		&.sld-product-grid-5 {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
		&.sld-product-grid-6 {
			text-align: center;
			align-self: center;
			width: 100%;
		}
        &.sld-product-grid-new, &.sld-product-grid-2 {
            @include media-breakpoint-down(sm) {
                width: 80%;
                margin: auto !important;
				padding: 0 !important;
            }
        }
		.buttons-sections {
			display: flex;
			flex-direction: column;
			.product-actions {
				display: inherit;
			}
			input {
				padding: 0.25rem 0;
				text-align: center;
			}
			button {
				width: 15px;
				font-size: 10px;
				background-color: #e1e1e1;
				border-radius: 0;
			}
		}
        .sld-product {
            display: block;
            width: 100%;
        }
        .sld-product-ref {
            width: 50%;
            padding: 8px 0;
            float: left;
        }
		div.sld-product-title {
            width: 50%;
            float: left;
			align-self: start;
			.product-name,
			.brand-name {
				display: inline-block;
			}
			.brand-name {
				color: #005ea8;
				font-weight: 700;
				font-size: 0.875rem;
				margin-right: 10px;
			}
			.product-reference {
				color: $color_text_light;
                padding-bottom: 5px;
			}
            .product-name {
                padding-bottom: 0;
            }
		}
        @include media-breakpoint-down(lg) {
            div.sld-product-title, .sld-product-ref {
            width: 100%;
            float: none;
            }
        }
		div.sld-product-description {
			align-self: start;
			.product-description-short {
				margin: 0;
				font-size: 1em;
				line-height: 1.2;
                color : $color_text_bold;
			}
		}
		
		.sld-guest {
			text-align: center;
			font-size: 0.75rem;
			div,
			a {
				display: block;
				margin: 0.5rem 0;
			}
			div {
				font-style: italic;
			}
		}

        .sld-stock, .first-prices, .eco-prices {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }
		.sld-product-price {
			text-align: right;
			font-size: 0.75rem;
			margin-bottom: 0.5rem;
		}

        .sld-promot-description {
                color: #fd5300;
                font-size: 0.8rem;
                margin-top: 5px;
                line-height: 120%;
            }
        .promot-prices {
            display: flex;
            justify-content: space-between;
            
            span:not(.description_promotion) {
                font-weight: bold;
                font-size: 0.75rem;
                display: inline-flex;
                align-items: center;
            }
            
        }
		.sld-product-price-total-label{
			padding-right: 0.5rem;
		}
		.sld-product-price-total-label,
		.sld-product-price-total,
		.sld-product-price-total-price {
			text-align: left;
			margin-bottom: 0.5rem;
			color:#005ea8;
			font-weight: bold;
			font-size: .875rem;
		}
		.product-quantity{
            .form-control {
                text-align: center;
            }
		}

		.sld-minimalquantity{
			color: #005ea8;
			font-weight: bold;
			margin: 0.25rem 0;
		}
	}
    .sld-txt {
			/* text-align: right; */
			font-size: 0.75rem;
			color: #005ea8;
			font-weight: bold;
			border-bottom: 1px solid#005ea8;
			margin-bottom: 6px;
		}
	.grid-border {
		position: relative;
		display: flex;
		flex-direction: column;
//		align-items: center;
		justify-content: center;
		padding: 0 0.5rem;
		line-height: 1;
        @include media-breakpoint-up(sm) {
            &::after {
                background-color: #f7f7f7;
                content: "";
                width: 4px;
                height: 100%;
                top: 0%;
                right: 0.5rem;
                position: absolute;
            }
        }
	}
	&.columns4 {
		grid-template-columns: 15% 35% 32.5% 17.5%;
	}
	.grid-item.sld-product-grid-2 {
		font-size: .8rem;
	}
	@include media-breakpoint-down(xs) {
		flex: 0 0 100% !important;
		max-width: 100% !important;
		grid-template-columns: inherit !important;
		.grid-item.sld-product-grid-2 {
			justify-content: center;	
			margin: 0.5rem 0 1.5rem 0;	
		}
	}
}

.sld-promot-description {
  color: #fd5300;
  font-size: 0.8rem;
  margin-top: 5px;
  line-height: 120%; }

.product-information .pfg-forms,
.product-list .pfg-forms {
	display: none;
	width: 100%;
	padding: 0.5rem;
	background-color: #fff;
	text-align: center;
	h3 {
		color: #005ea8;
		font-size: 1.25rem;
		border-top: 1px solid #005ea8;
		padding-top: 0.5rem;
	}
	textarea {
		height: 5rem;
	}
	label {
		width: 100%;
		max-width: none;
		text-align: center !important;
		& + div {
			width: 100%;
			max-width: none;
		}
	}
	.hide {
		display: none;
	}
	.submit {
		justify-content: center;
	}
}

/***********waiting loader*************/
#preloader_2 {
	display: none;
	position: relative;
	left: 50%;
	width: 40px;
	height: 40px;
}
#preloader_2 span {
	display: block;
	bottom: 0px;
	width: 30px;
	height: 30px;
	background: #9b59b6;
	position: absolute;
}
#preloader_2 span:nth-child(1) {
	-webkit-animation: preloader_1_1 1.5s infinite ease-in-out;
}
#preloader_2 span:nth-child(2) {
	left: 35px;
	-webkit-animation: preloader_1_2 1.5s infinite ease-in-out;
}

@-webkit-keyframes preloader_1_1 {
	0% {
		-webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
		border-radius: 0px;
	}
	50% {
		-webkit-transform: translateX(40px) rotate(-180deg);
		border-radius: 20px;
		background: #3498db;
	}
	100% {
		-webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
		border-radius: 0px;
	}
}
@-webkit-keyframes preloader_1_2 {
	0% {
		-webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
		border-radius: 0px;
	}
	50% {
		-webkit-transform: translateX(-40px) rotate(180deg);
		border-radius: 20px;
		background: #f1c40f;
	}
	100% {
		-webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
		border-radius: 0px;
	}
}
/***********fin waiting loader*************/

/***********historiue de commendes*************/
.sld-history{
	display: flex;
	flex-direction: column;
	border: 1px solid #c7c7c7;
	border-radius: 5px;
	background-color: #fff;
    overflow: hidden;
	.sld-history-ctn{
		display: flex;
		justify-content: space-between;
		align-items: center;
		> div{
			width: calc(45% / 2);
			padding: 1rem 0.5rem;
			&.sld-history-order-block1{
				width: 30%;
				display: flex;
				flex-direction: column;
			}
			&.sld-history-order-block2{
				width: 25%;
				span{
					padding: 0.25rem 0.1rem;
					color: #fff;
					white-space: nowrap;
					border-radius: 3px;
				}	
			}
			&.sld-history-order-block3{
				.sld-history-order-block3-piece{				
					display: flex;
					justify-content: space-between;
					align-items: center;
					overflow: hidden;
					margin: 0.5rem 0;
					a{
						color: #5d5d5d;
					}
					i{
						font-size: 1.5rem;
					}
					div{
						font-size: 0.75rem;
						line-height: 1rem;
					}
				}		
			}
		}
		&.sld-history-title{
			background-color: #ebebeb;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 1rem;
		}		
		&.sld-history-order{
			border-bottom: 1px solid #c7c7c7;
			
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			> div{
				width: 100% !important;
				text-align: center;
				padding: 0.5rem 1rem;
				.sld-history-order-block3-piece{				
					justify-content: center !important;
				}	
			}
		}
	}	
}
/***********fin historiue de commendes*************/

.aone-slideshow .nivoSlider {
	/*padding-bottom: 5rem;*/
	.nivo-controlNav {
		display: flex;
		justify-content: center;
		left: 25% !important;
		width: 50% !important;
		padding: 0 0 1rem 0;
		.nivo-control {
			/*width: 150px !important;*/
			background: none !important;
			border: 5px solid #fff;
			border-radius: 0;
			img {
				position: initial;
			}
			&.active {
				border-color: #005ea8;
			}
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}
.sld-registration-top {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	border: 2px solid #005ea8;
	padding: 1rem;
	background: #f7f7f7;
	font-size: 1rem;
	ul {
		padding-left: 1rem;
		margin-bottom: 1rem;
		li {
			list-style: square;
		}
	}
	div {
		&.bold {
			font-weight: bold;
			margin-top: 1rem;
		}
	}
}
form.pfg-forms {
	h3 {
		display: none;
	}
	.form-group {
		display: flex;
		align-items: center;
		label {
			padding-right: 1rem;
		}
		/*&.document .form-control-label,
	&.newsletter .form-control-label,
	&.cgv .form-control-label{
		display: none;
	}*/
	}
	.submit {
		justify-content: flex-end;
		margin-right: 0;
	}
	.sld-icones {
		display: flex;
		align-items: stretch;
		div {
			display: flex;
			align-items: center;
			padding: 0 0.75rem;
			background: #f7f7f7;
			border: 1px solid #3d3d3d;
		}
		span{
			display: flex;
			align-items: center;
			label.input{
				margin: 0.5rem !important;
			}
		}
		.g-recaptcha div,
		.g-recaptcha{
			border: none;
			padding: 0;
		}
	}
	.sld-form-header{
		text-align: center;
		margin-bottom: 1rem;
		font-size: 1rem;
		
	}
}
#module-powerfulformgenerator-display #center-column{
	width: 75%;
	flex: 0 0 75%;
}
form#pfg-form-4,
form#pfg-form-1{
	h3{
		display: inherit;
		text-align: center;
		margin-bottom: 2rem;
	}
}
.product-quantity .input-group .input-group-btn i {
    color: $color_blue;
}
.as4-loader{
	z-index: 9999999;
}
#cart {
    .cart-container-wrapper .sld-cart {
        position: relative;
    }
    .product-quantity .input-group {
        width: 80%;
        margin-left: 20%;
    }
}
.product-quantity .input-group {
    flex-wrap: nowrap;
}
//Mon compte


