@import "../partials/variables";

.product-quantity ~ .sld-buylater {
  margin-left: 15px;
  @include media-breakpoint-only(lg) {
    margin-left: 5px;
  }
}

.sld-promot-description {
  color: #fd5300;
  font-size: 0.8rem;
  margin-top: 5px;
  line-height: 120%; }

.product-information .pfg-forms,
.product-list .pfg-forms {
  display: none;
  width: 100%;
  padding: 0.5rem;
  background-color: #fff;
  text-align: center;
  h3 {
    color: #005ea8;
    font-size: 1.25rem;
    border-top: 1px solid #005ea8;
    padding-top: 0.5rem;
  }
  textarea {
    height: 5rem;
  }
  label {
    width: 100%;
    max-width: none;
    text-align: center !important;
    & + div {
      width: 100%;
      max-width: none;
    }
  }
  .hide {
    display: none;
  }
  .submit {
    justify-content: center;
  }
}
.product-information {
  .sld-product-price-total {
    padding-top: 6px;
  }
}
.product-quantity .input-group {
  flex-wrap: nowrap;
  max-width: 140px;
}
.product-quantity .input-group .input-group-btn i {
  color: $color_blue;
}
.as4-loader{
  z-index: 9999999;
}
.main-product-bottom {
  #collapseViewed {
    opacity: 1;
  }
}
#product {
  .viewed-products {
    .product-price {
      color: #49C84C;
    }
  }
}