@import "../partials/variables";
@import "block-reassurance";
@import "imageslider";
@import "mainmenu";
@import "search-widget";
@import "featuredproducts";
@import "ps_socialfollow";
@import "ps_emailsubscription";
@import "ps_sharebuttons";
@import "ps_customer_signin";
@import "aeuc_front";
@import "ambjolisearch";

.currency-selector-wrapper, .language-selector-wrapper {
}
.currency-selector-wrapper + .language-selector-wrapper, .language-selector-wrapper + .currency-selector-wrapper {
  margin-left: 5px;
}
.currency-selector, .language-selector {
  .dropdown-current {
    padding: 5px 8px;
    background-color: $color_gray;
    border-radius: $border-radius;
    @include media-breakpoint-down(sm) {
      padding: 3px 5px;
    }
  }
  .dropdown-icon {
    .material-icons, .fa {
      font-size: 15px;
    }
  }
  .dropdown-menu {
    min-width: 0;
  }
  .dropdown-item {
    @include media-breakpoint-down(sm) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.language-selector {
  #language-selector-label {
    display: none;
  }
  .l-code {
    display: none;
  }
  .l-name {
    display: flex;
  }
  img {
    margin-top: -2px;
  }
}
.currency-selector {
  #currency-selector-label {
    display: none;
  }
  .dropdown-menu {
  }
  .c-sign {
    display: inline-block;
  }
}
.sidebar-currency {
  border-top: 1px solid $border-color;
  padding: 20px 10px;
  .currency-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 0 3px;
    }
    .dropdown-item {
      border-radius: $border-radius;
      padding: 5px 8px;
    }
    .current .dropdown-item {
      background: $color_green;
      color: $light-text-color;
    }
  }
  .c-sign {
    display: none;
  } 
}
.sidebar-language {
  border-top: 1px solid $border-color;
  padding: 20px 10px;
  .l-name {
    display: none;
  }
  .language-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin: 0 3px;
    }
    .dropdown-item {
      border-radius: $border-radius;
      padding: 5px 8px;
      text-transform: uppercase;
      min-width: 40px;
      text-align: center;
    }
    .current .dropdown-item {
      background: $color_green;
      color: $light-text-color;
    }
  }
}



.sidebar-account {
  padding: 20px 10px;
  border-top: 1px solid $border-color;
  background: $color_gray;
}
.sidebar-header-phone {
  padding: 15px;
  border-top: 1px solid $border-color;
  background: $color_gray;
  ul li {
    padding: 6px;
  }
}
.st-menu-title {
  background: $color_gray;
}
.sidebar-menu {
  padding: 15px;
  border-top: 1px solid $border-color;
}
.sidebar-categories {
  .category-tree {
    .category-sub-menu {
      display: none;
    }
    .collapse-icons {
      right: -8px;
    }
  }
}


.cart-preview {
  position: relative;
  .cart-header {
    text-align: center;
    .cart-link {
      display: flex;
      align-items: center;
      border-radius: $border-radius;
      padding: 5px 10px;
      border-width: 2px;
      border-style: solid;
      font-weight: 700;
      font-size: rem(16);
      position: relative;
      white-space: nowrap;
      transition: all 0.3s;
    }
    i {
      font-size: 22px;
      line-height: 22px;
      margin-right: 4px;
    }
    .cart-total-value {
      font-size: rem(16);
      font-weight: 400;
    }
    .cart-products-count {
      display: block;
      text-align: center;
      min-width: 16px;
      height: 16px;
      line-height: 16px;
      background-color: #20BC24;
      color: #fff;
      border-radius: 16px;
      font-size: 10px;
      padding: 0 4px;
      position: absolute;
      top: -10px;
      right: -2px;
      transition: all 0.3s;
    }
  }
  .cart-dropdown {
    position: absolute;
    top: 45px;
    right: 0;
    width: 300px;
    padding: 0;
    z-index: 999;
    background: $light-background;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 2px 4px $box-shadow-color;
    border-radius: $border-radius;
    overflow: hidden;
  }
  .cart-title {
    background: $color_gray;
    padding: 15px 5px;
    border-bottom: 1px solid $border-color;
    h3, h4 {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 1rem;
      text-align: center;
    }
  }
  .cart-items {
    padding: 5px 10px;
  }
  .cart-product-line {
    padding: 12px 0;
    display: flex;
    align-items: center;
    + .cart-product-line {
      border-top: 1px dashed $border-color;
    }
    .product-image {
      flex: none;
      max-width: 80px;
    }
    .product-infos {
      width: 100%;
      padding-left: 8px;
      align-self: flex-start;
    }
    .remove-from-cart {
      flex: none;
      font-size: 14px;
    }
    .product-name {
      clear: both;
      display: inline-block;
      padding: 0;
    }
    .product-attributes {
      display: block;
      font-style: italic;
    }
    .product-price-quantity {
      display: flex;
      margin-top: 9px;
    }
    .product-price {
      font-size: 1rem;
      font-weight: 700;
    }
    .x-character {
      display: inline-block;
      width: 12px;
      text-align: center;
    }
    .product-quantity {
      display: none;
      .input-group {
        .input-group-btn > .btn {
          width: 25px;
          height: 25px;
          line-height: 23px;
          font-size: 14px;
        }
        input {
          padding-left: 2px;
          padding-right: 2px;
          width: 40px;
          height: 25px;
          text-align: center;
        }
      }
    }
  }
  .allow-update-quantity .cart-product-line {
    .product-price-quantity {
      justify-content: space-between;
      align-items: center;
    }
    .product-quantity {
      display: block;
    }
    .x-character, .product-qty {
      display: none;
    }
    .remove-from-cart {
      align-self: normal;
      margin-top: 21px;
    }
  }

  .cart-bottom {
    padding: 10px 15px;
    background: $color_gray;
    border-top: 1px solid $border-color;
  }
  .total-line {
    padding: 5px 0 0;
    display: flex;
    .value {
      margin-left: auto;
    }
  }
  .cart-subtotals {
    .total-line .value {
      font-size: 0.9rem;
    }
  }
  .cart-total {
    label, .label {
      text-transform: uppercase;
    }
    .tax-short {
      display: none;
    }
  }
  .cart-tax {
    padding-top: 0;
    display: none;
  }
  .cart-action {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .no-items {
    padding: 15px;
    text-align: center;
  }

  &:hover, &.hover {
    .cart-products-count {
      color: #fff;
    }
  }

  @include media-breakpoint-up(md) {
    &:hover, &.hover {
      .cart-dropdown {
        visibility: visible;
        opacity: 1;
        top: 35px;
        transition: all 0.3s;
      }
    }
  }
}

.left-brand-list, .left-supplier-list {
  .linklist {
    margin-top: -3px;
  }
}

.mailalert-product-page {
  .mailalert-form {
    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .alert-email {
    min-width: 200px;
  }
  .alert-button {
    margin-right: 15px;
    width: 100%;
    .btn {
      width: 100%;
      text-transform: none;
      padding: 10px;
    }
  }
  .mailalert-msg {
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    .mailalert-form {
      flex-direction: column;
    }
    .alert-email {
      width: 100%;
    }
  }
}

.my-mail-alerts {
  padding-top: 0;
  padding-bottom: 0;
  .mail-alert-line {
    padding: $box-padding 0;
    + .mail-alert-line {
      border-top: 1px solid $border-color-lighten;
    }
    .p-image img {
      max-width: 80px;
    }
    .p-name {
      margin-left: 10px;
    }
    .p-remove {
      margin-left: auto;
    }
  }
}
