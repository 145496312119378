#module-sld_b2b-CustomerContact .page-subheading {
  padding-left: 10%;
}
.sld-border {
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
}
#module-sld_b2b-CustomerContactRights .box, #module-sld_b2b-CustomerContact .box {
  @extend .sld-border;
}
#module-sld_b2b-CustomerContactRights .box .table tr:first-child th {
  border-top: none;
}
#module-powerfulformgenerator-display #main .box, #module-shoppinglist-accountshoppinglist .box, #module-psgdpr-gdpr #content {
  @extend .sld-border;
  padding: 10px;
}
#module-psgdpr-gdpr .psgdprinfo17 {
  background: none;
}
.st-menu-right .total-line.shipping {
  display: none;
}
.h1-replace, .h2-replace, .h3-replace, .h4-replace, .h5-replace, .h6-replace {
  font-family: "Roboto", sans-serif;
  color: #3d3d3d;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: inherit;
}

.sld-registration-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  border: 2px solid #005ea8;
  padding: 1rem;
  background: #f7f7f7;
  font-size: 1rem;
  ul {
    padding-left: 1rem;
    margin-bottom: 1rem;
    li {
      list-style: square;
    }
  }
  div {
    &.bold {
      font-weight: bold;
      margin-top: 1rem;
    }
  }
}
form.pfg-forms {
  h3 {
    display: none;
  }
  .form-group {
    display: flex;
    align-items: center;
    label {
      padding-right: 1rem;
    }
  }
  .submit {
    justify-content: flex-end;
    margin-right: 0;
  }
  .sld-icones {
    display: flex;
    align-items: stretch;
    div {
      display: flex;
      align-items: center;
      padding: 0 0.75rem;
      background: #f7f7f7;
      border: 1px solid #3d3d3d;
    }
    span{
      display: flex;
      align-items: center;
      label.input{
        margin: 0.5rem !important;
      }
    }
    .g-recaptcha div,
    .g-recaptcha{
      border: none;
      padding: 0;
    }
  }
  .sld-form-header{
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1rem;

  }
}
#module-powerfulformgenerator-display #center-column{
  width: 75%;
  flex: 0 0 75%;
}
form#pfg-form-4,
form#pfg-form-1{
  h3{
    display: inherit;
    text-align: center;
    margin-bottom: 2rem;
  }
}

// ps customer account
.block-myaccount-infos {
  padding: 0;
  background-color: transparent;
  border: none;
  .account-list {
    li {
      position: relative;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: rem(16);
        padding: 12px 16px;
        border-left: 2px solid transparent;
        &.active {
          border-color: $color_primary;
          color: $color_primary;
        }
      }
      &+ li {
        &:after {
          content: '';
          display: block;
          width: calc(100% - 16px);
          height: 1px;
          position: absolute;
          z-index: 10;
          top: 0;
          right: 0;
          background-color: #E3E3E3;
        }
      }
      &:last-child {
        &:before {
          content: '';
          display: block;
          width: 16px;
          height: 1px;
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          background-color: #f7f7f7;
        }
      }
    }
  }
}
.my-account-title {
  font-size: rem(18);
  font-weight: 700;
  line-height: rem(24);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 32px 30px 16px;
  a {
    color: inherit;
  }
}
// END - ps customer account