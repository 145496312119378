@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?lf5jrc') format('truetype'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?lf5jrc') format('woff'),
          url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?lf5jrc##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="sld-icon-"], [class*=" sld-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sld-icon-credit-card {
  &:before {
    content: $sld-icon-credit-card;
  }
}
.sld-icon-delivery {
  &:before {
    content: $sld-icon-delivery;
  }
}
.sld-icon-map {
  &:before {
    content: $sld-icon-map;
  }
}
.sld-icon-remove {
  &:before {
    content: $sld-icon-remove;
  }
}
.sld-icon-filter {
  &:before {
    content: $sld-icon-filter;
  }
}
.sld-icon-menu {
  &:before {
    content: $sld-icon-menu;
  }
}
.sld-icon-search {
  &:before {
    content: $sld-icon-search;
  }
}
.sld-icon-user {
  &:before {
    content: $sld-icon-user;
  }
}
.sld-icon-cart {
  &:before {
    content: $sld-icon-cart;
  }
}
.sld-icon-cookie {
  &:before {
    content: $sld-icon-cookie;
  }
}
.sld-icon-facebook {
  &:before {
    content: $sld-icon-facebook;
  }
}
.sld-icon-instagram {
  &:before {
    content: $sld-icon-instagram;
  }
}
.sld-icon-linkedin {
  &:before {
    content: $sld-icon-linkedin;
  }
}
.sld-icon-pinterest {
  &:before {
    content: $sld-icon-pinterest;
  }
}
.sld-icon-rss {
  &:before {
    content: $sld-icon-rss;
  }
}
.sld-icon-twitter {
  &:before {
    content: $sld-icon-twitter;
  }
}
.sld-icon-vimeo {
  &:before {
    content: $sld-icon-vimeo;
  }
}
.sld-icon-youtube {
  &:before {
    content: $sld-icon-youtube;
  }
}

