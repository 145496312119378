

.ui-menu.ui-jolisearch {
  overflow: inherit;
  background-color: #fff;
  z-index: 9999;
  .jolisearch-body {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    overflow: hidden;
    span {
      &.jolisearch-title{
        margin-top: -35px !important;
      }
      &.jolisearch-name{
        color: #005ea8;
        font-size: 0.8rem !important;
      }
    }
    aside[role="complementary"] {
      width: 30%;
      order: 2;
      &> div {
        padding-bottom: 9999px;
        margin-bottom: -9999px;
        + div {
          margin-top: 1.5rem;
        }
      }
      a {
        display: inline;
        padding: 0;
        line-height: 1.5em;
        font-weight: 600;
      }
      .items-count {
        color: #5D5D5D;
        line-height: 1.5em;
        font-weight: normal;
      }
      li {
        border: 1px solid transparent;
        padding: 0.5rem;
        &:hover {
          background-color: #efefef;
          border: 1px solid #dfdfdf;
        }
      }
    }
    section[role="main"] {
      width: 70%;
      .items-count {
        color: white;
        background-color: #ccc;
        padding: 3px 10px;
        border-radius: 20px;
        font-size: 0.8em;
        font-weight: normal;
        display: inline-block;
        vertical-align: bottom;
      }
      .product {
        padding: 0.5rem;
        &:hover {
          background-color: #efefef;
        }
        div.jolisearch-product {
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            display: block;
            font-size: 14px;
            line-height: 18px;
          }
          .product-name {
            padding-top: 0;
            padding-bottom: 2px;
          }
          .product-price {
            color: #21AB00;
          }
        }
        img {
          display: inline-block;
          width: 60px;
          height: auto;
          margin-right: 10px;
        }
      }
      .more-results {
        text-overflow: ellipsis;
        text-align: center;
        font-size: 0.95em;
        color: #b5b5b5;
        padding: 0.5rem;
        a {
          border: 1px solid transparent;
          &:hover {
            background-color: #efefef;
            border: 1px solid #dfdfdf;
          }
        }
      }
    }
  }
  .jolisearch-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: calc(100vh - 280px);
    .product {
      width: 100%;
      padding-left: 15px;
      &:nth-of-type(even) {
        background-color: #F7F7F7;
      }
    }
  }
  .jolisearch-content {
    .items-count {
      margin-left: 10px;
      font-size: rem(18);
      line-height: rem(18);
      padding: 2px;
    }
  }
  a.jolisearch-product {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .h4 {
    display: flex;
    font-size: rem(22);
    line-height: rem(26);
    border-bottom: 1px solid #F7F7F7;
    margin-bottom: 1rem;
    padding: 10px 0 0.5rem;
    font-weight: 500;
    color: $color_primary;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .ui-menu.ui-jolisearch .jolisearch-body {
    width: 502px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ui-menu.ui-jolisearch .jolisearch-body {
    width: 682px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .ui-menu.ui-jolisearch .jolisearch-body {
    width: 922px;
  }
}
@media (min-width: 1200px) {
  .ui-menu.ui-jolisearch .jolisearch-body {
    width: 1102px;
  }
}