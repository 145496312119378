@import "../partials/variables";

.user-info {
  text-align: center;
  li {
    display: flex;
    position: relative;
  }
  .create-account-link,
  .account-link {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    font-size: 0.875rem;
    line-height: 0.875rem;
    border: 2px solid $color_primary;
    white-space: nowrap;
    transition: $transition color, $transition background-color;
  }
  .create-account-link {
    color: $color_primary;
    margin-right: 8px;
    &:hover {
      color: #fff;
      background-color: $color_primary;
    }
  }
  .account-link {
    color: $color_primary;
    &:hover {
      background-color: $color_primary;
      color: #fff;
    }
  }
  .account-title {
    font-size: rem(16);
  }
  .sld-icon-user {
    font-size: 20px;
    line-height: 20px;
    margin-right: 6px;
  }
  .logout-link {
    display: none;
  }
  .dropdown-customer-account-links {
    position: absolute;
    top: 110%;
    right: 0;
    min-width: 100%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    .dropdown-menu {
      display: block;
      width: 100%;
      text-align: right;
      position: initial;
      border: 2px solid;
      border-radius: $border-radius 0 $border-radius $border-radius;
    }
    .dropdown-item {
      position: relative;
      .material-icons, .fa {
        position: absolute;
        left: 10px;
        top: 12px;
      }
      > span {
        margin-left: 20px;
      }
    }
    .logout {
      padding: $base-padding;
      text-align: center;
      color: red;
      border-top: 1px solid $border-color;
      i {
        min-width: 18px;
      }
    }
  }
  .customer-logged {
    position: relative;
    &:hover, .hover {
      .dropdown-customer-account-links {
        visibility: visible;
        opacity: 1;
        top: calc(100% - 2px);
        transition: all 0.3s;
      }
    }
  }
}

.sidebar-account {
  &.user-info {
    .account-link {
      display: inline-block;
    }
    .logout-link {
      display: inline-block;
      margin-left: 10%;
      color: red;
    }
    .dropdown-customer-account-links {
      display: none;
    }
  }
}

.sld-dropdown-customer-connexion {
  position: absolute;
  z-index: 1000;
  top: 100%;
  right: 10px;
  border: 1px solid rgba(#5D5D5D, .24);
  background-color: #fff;
  padding: 0 8px;
  opacity: 0;
  visibility: hidden;
  transition: $transition opacity, $transition visibility;
  text-align: right;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
.sld-dropdown-customer-link {
  display: block;
  margin: 6px 0;
  white-space: nowrap;
}