@import "../partials/variables";

$menu_title_color:          $color_white;
$menu_title_color_hover:    $color_supp;
//$menu-background:           #f7f7f7;
$menu-background:           $color_green;
$container-width-md:        map-get($container-max-widths, md);
$container-width-lg:        map-get($container-max-widths, lg);
$container-width-xl:        map-get($container-max-widths, xl);

// debug dropdown
//.amenu-item.plex:first-child {
//  .adropdown {
//    opacity: 1;
//    visibility: visible;
//    top: 100%;
//  }
//}

.header-main-menu {
  background: $menu-background;
}
.anav-top {
  margin: 0 0 0 45px;
  position: relative;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  $menu_link_horizontal_padding: 15px;
  $menu_link_font_size: rem(16);
  .amenu-link {
    color: $menu_title_color;
    font-size: $menu_link_font_size;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $title-font;
    line-height: 20px;
    padding: 15px $menu_link_horizontal_padding;
    text-align: center;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    > sup {
      position: absolute;
      top: -5px;
      right: 5px;
      line-height: 13px;
      padding: 1px 3px;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      background-color: $color_pink;
      color: $color_pink;
      font-size: 10px;
      display: inline-block;
      border-radius: 1px;
      transition: all 0.2s;
      white-space: nowrap;
      span {
        color: $light-text-color;
      }
    }
  }
  .amenu-item {
    margin: 0 5px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      &:hover, &.hover {
        background: $light-background;
        color: $menu_title_color_hover;
        .amenu-link {
          color: $menu_title_color_hover;
        }
      }
    }
    &:last-child {
      .amenu-link {
        z-index: 0;
        background-color: inherit;
        &:before {
          content: '';
          display: block;
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          position: absolute;
          z-index: -1;
          top: -4px;
          left: -4px;
          right: -4px;
          bottom: -4px;
          background-color: inherit;
          transition: $transition background-color;
          box-shadow: 0 3px 6px rgba(0,0,0,.3);
        }
      }
      &:hover {
        .amenu-link {
          &:before {
            background-color: #fff;
          }
        }
      }
    }
    &.right {
      margin-left: auto;
    }
  }
  .mobile-toggle-plus {
    position: absolute;
    right: 5px;
    top: 23px;
    cursor: pointer;
    display: none;
    .caret-down-icon {
      display: block;
      border-style: solid;
      border-width: 6px 5px 0 5px;
      border-color: transparent;
      border-top-color: $menu_title_color;
      border-bottom-color: $menu_title_color;
    }
  }
  $menu_link_horizontal_padding_lg: 10px;
  $menu_link_font_size_lg: 1rem;
  @include media-breakpoint-only(lg) {
    .amenu-link {
      padding-left: $menu_link_horizontal_padding_lg;
      padding-right: $menu_link_horizontal_padding_lg;
      font-size: $menu_link_font_size_lg;
      > sup {
        right: 0;
      }
    }
    .mobile-toggle-plus {
      right: 2px;
    }
  }
  $menu_link_horizontal_padding_md: 3px;
  $menu_link_font_size_md: 0.8rem;
  @include media-breakpoint-only(md) {
    .amenu-link {
      padding-left: $menu_link_horizontal_padding_md;
      padding-right: $menu_link_horizontal_padding_md;
      font-size: $menu_link_font_size_md;
      > sup {
        right: -3px;
      }
    }
    .mobile-toggle-plus {
      right: -3px;
    }
  }
  .dropdown-menu-title {
    font-size: rem(20);
    font-weight: 700;
    color: #5D5D5D;
    @include media-breakpoint-up(lg) {
      padding: 30px 16px 10px;
    }
  }
  .adropdown {
    position: absolute;
    left: 0;
    top: 110%;
    max-width: 100%;
    z-index: 1000;
    background: $light-background;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 2px 4px $box-shadow-color;
    border-radius: 0 0 5px 5px;
    @include media-breakpoint-up(md) {
      max-width: $container-width-md - $grid-gutter-width;
    }
    @include media-breakpoint-up(lg) {
      max-width: $container-width-lg - $grid-gutter-width;
    }
    @include media-breakpoint-up(xl) {
      max-width: $container-width-xl - $grid-gutter-width;
    }
    .dropdown-wrapper {
      padding: 10px 5px;
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      max-height: calc(100vh - 210px);
    }
    .dropdown-bgimage {
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat;
      border-radius: 0 0 5px 5px;
    }
    .dropdown-content {
      width: 100%;
    }
  }
  $zdropdown-1-width:     ($container-width-xl - $grid-gutter-width) / 5;
  .adropdown-1 { 
    width: $zdropdown-1-width; 
  }
  .adropdown-2 { 
    width: $zdropdown-1-width * 2;
    .acontent-1 { width: percentage(1/2); }
  }
  .adropdown-3 { 
    width: $zdropdown-1-width * 3;
    .acontent-1 { width: percentage(1/3); }
    .acontent-2 { width: percentage(2/3); }
  }
  .adropdown-4 {
    width: $zdropdown-1-width * 4;
    .acontent-1 { width:percentage(1/4); }
    .acontent-2 { width: percentage(2/4); }
    .acontent-3 { width: percentage(3/4); }
  }
  .adropdown-5 {
    width: $zdropdown-1-width * 5;
    .acontent-1 { width: percentage(1/5); }
    .acontent-2 { width: percentage(2/5); }
    .acontent-3 { width: percentage(3/5); }
    .acontent-4 { width: percentage(4/5); }
  }

  .categories-grid, .products-grid, .manufacturers-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .category-item, .product-item, .manufacturer-item {
    padding: 10px;
    width: 100%;
  }
  .acontent-1 {
    .category-item, .product-item, .manufacturer-item { width: percentage(1); }
  }
  .acontent-2 {
    .category-item, .product-item, .manufacturer-item { width: percentage(1/2); }
  }
  .acontent-3 {
    .category-item, .product-item, .manufacturer-item { width: percentage(1/3); }
  }
  .acontent-4 {
    .category-item, .product-item, .manufacturer-item { width: percentage(1/4); }
  }
  .acontent-5 {
    .category-item, .product-item, .manufacturer-item { width: percentage(1/5); }
  }
  .category-item {
    .category-title {
      font-size: rem(16);
      color: $color_primary;
      margin-bottom: rem(14);
      a {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
    .category-thumb {
      display: none;
    }
    .category-subs {
      li {
        padding: $base-padding 0;
        a {
          display: flex;
          position: relative;
          color: $color_text_bold;

          &:before {
            content: "\e5cc";
            font-family: $font_material;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            display: inline-block;
          }
          &:hover {
            span {
              &:after {
                width: 100%;
                opacity: 1;
              }
            }
            img {
              max-height: 32px;
              margin: -8px 5px -6px -21px;
            }
          }
          span {
            position: relative;
            &:after {
              content: '';
              width: 0;
              opacity: 0;
              height: 2px;
              background-color: $color_text_bold;
              transition: $transition width, $transition opacity;
              position: absolute;
              bottom: -2px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .product-item {
    margin-bottom: 0;
    .product-container {
      padding-bottom: 10px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    .product-name {
      text-align: center;
    }
  }
  .manufacturer-item {
    .middle-side {
      display: none;
    }
    .logo {
      max-width: 200px;
      img {
        box-shadow: none;
      }
    }
  }
  .html-item {
    padding: 5px 10px;
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
    }
  }

  .category-full-width, .category-horizontally {
    .category-item {
      width: 100%;
      ul {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &.acontent-1 {
      .category-item li { width: percentage(1); }
    }
    &.acontent-2 {
      .category-item li { width: percentage(1/2); }
    }
    &.acontent-3 {
      .category-item li { width: percentage(1/3); }
    }
    &.acontent-4 {
      .category-item li { width: percentage(1/4); }
    }
    &.acontent-5 {
      .category-item li { width: percentage(1/5); }
    }
  }
  .small-category-title {
    .category-item {
      padding-top: 6px;
      padding-bottom: 6px;
      .category-title {
        margin-bottom: 0;
        a {
          text-transform: none;
          font-weight: 400;
        }
      }
    }
  }
  .brand-linklist {
    .manufacturer-item {
      text-align: left;
      padding-top: 6px;
      padding-bottom: 6px;
      .logo {
        display: none;
      }
      .middle-side {
        display: block;
        margin-top: 0;
        .product-name {
          &:before {
            content: '\F105';
            display: inline-block;
            font-family: $font_awesome;
            padding-right: 8px;
          }
        }
      }
    }
  }
    
  @include media-breakpoint-up(md) {
    .amenu-item.plex {
      &:hover, &.hover {
        .adropdown {
          visibility: visible;
          opacity: 1;
          top: 100%;
          transition: all 0.3s;
        }
      }
    }    
  }
}

.is-sticky {
  .anav-top .amenu-link > sup {
    top: -2px;
  }
}

.mobile-amega-menu {
  background: $menu-background;
  border: 1px solid $border-color;
  border-radius: 5px;
  padding: 5px;
  overflow: auto;
  max-height: calc(100vh - 250px);
}
.anav-mobile {
  display: block;
  margin: 0;
  .amenu-link {
    text-align: left;
    padding: 13px 10px;
    > sup {
      position: inherit;
      top: -8px;
      margin-left: 5px;
      &:after {
        display: none;
      }
    }
  }
  .amenu-item {
    margin: 0;
    flex-direction: column;
    align-items: normal;
    transition: all 0.3s;
    + .amenu-item {
      border-top: 1px dashed $border-color;
    }
  }
  .mobile-toggle-plus {
    width: 20px;
    height: 24px;
    top: 13px;
  }

  .adropdown {
    display: none;
    visibility: visible;
    opacity: 1;
    position: relative;
    top: 0!important;
    width: 100%;
    padding: 0;
    box-shadow: none;
    background: transparent;
    .dropdown-wrapper {
      background: $light-background;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    [class*="acontent-"] {
      width: 100%;
    }
    .category-item {
      width: 100%;
    }
    .category-horizontally[class*="acontent-"] .category-item li {
      width: percentage(1);
    }
    .product-item, .manufacturer-item {
      width: 50%;
    }
  }
  
}