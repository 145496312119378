// product list
.product-list {
  #comparisonform {
    margin-bottom: 0;
    text-align: right;
    .compare-form {
      display: none;
    }
    .compareboxproduct {
      height: 24px;
    }
  }
}
// END - product list

#category {
  @include media-breakpoint-down(sm) {
    .main-content > .container > .row {
      flex-direction: column-reverse;
    }
  }
}
#category,
#module-pm_advancedsearch4-searchresults {
  .card-block {
    padding: 0;
  }
  .pm_advancedsearch4_header {
    .sld-js-sort-orders {
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 20px;
      label {
        text-align: left;
        font-weight: 400;
        padding-bottom: 6px;
        text-transform: uppercase;
        font-size: rem(15);
        font-weight: 600;
      }
      .sort-select {
        cursor: pointer;
        &:after {
          content: '\e5cf';
          font-family: $font_material;
          position: absolute;
          z-index: 10;
          top: 8px;
          right: 4px;
        }
      }
      .sort-select,
      .custom-select {
        background: #004F8D;
        color: #fff;
      }
      .dropdown-menu {
        width: 100%;
        background: linear-gradient(to bottom, #004F8D, $color_primary);
      }
      .dropdown-item {
        color: #fff;
        &:hover {
          color: $color_text;
        }
      }
    }
  }
}
.a-mobile-device .sld-js-filter-mobile {
  border: none;
  background-color: transparent;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: #5D5D5D;
  .sld-icon-filter {
    margin-right: 6px;
  }
}