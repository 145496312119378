$brand-block-padding: 20px;
.brand-list {
  margin: 0 ($brand-block-padding / -2);
}
.brand-base {
  padding: 5px ($brand-block-padding / 2);
  text-align: center;
  .logo {
    img {
      margin: auto;
      border-radius: 5px;
      box-shadow: 0px 1px 5px $box-shadow-color;
      transition: all 0.3s;
    }
    a:hover img {
      filter: brightness(75%);
    }
  }
  .middle-side {
    margin-top: 10px;
  }
}

.brand-list {
  &.slider-mode {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    &.slick-slider {
      display: block;
      overflow: visible;
    }
  }
  &.list-mode {
    display: flex;
    flex-wrap: wrap;
    .brand-base {
      margin-bottom: 8px;
    }

    @include media-breakpoint-down(xs) {
      margin: 0 -5px;
      .brand-base {
        margin-bottom: 0;
        padding: 5px 5px;
      }
    }
  }

  &:not(.slick-slider) {
    .brand-base {
      @include make-col(1, 2);
      @include media-breakpoint-up(sm) {
        @include make-col(1, 3);
      }
      @include media-breakpoint-up(md) {
        @include make-col(1, 4);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(1, 5);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(1, 6);
      }
    }
  }
}

.aone-brands {
  margin-bottom: 20px;
  .title-block {
    display: none;
  }
  .slick-prev, .slick-next {
    top: 50%;
    margin-top: -8px;
    opacity: 0;
  }
  .slick-prev {
    left: -5px;
  }
  .slick-next {
    right: -5px;
  }
  .slick-slider:hover {
    .slick-prev, .slick-next {
      opacity: 0.5;
    }    
  }
}
.brand-list-wrapper {
  .slick-slider {
    margin-bottom: 20px;
  }
  .slick-track {
    align-items: center;
  }
}