$font1: 'roboto';
//$font2: 'poppins';

$fonts: (
        ("name": "#{$font1}", "styles": ('regular': 400, 'regular-italic': 400, 'medium': 500, 'bold': 700), "path":'#{$path_fonts}#{$font1}/#{$font1}-'),
  //("name": "#{$font2}", "styles": ('bold': 700, 'bold-italic': 700, 'light': 300, 'light-italic': 300, 'regular': 400, 'regular-italic':400), "path":'#{$path_fonts}#{$font2}/#{$font2}-')
);

@each $font in $fonts {
  $font-styles: map-get($font,"styles");
  @each $style, $weight in $font-styles {
    @font-face {
      font-family: '#{map-get($font,"name")}';
      @if str-index($style, 'italic') {
        font-style: italic;
      }
      @else {
        font-style: normal;
      }
      font-weight: $weight;
      src: local(''),
      url('#{map-get($font,"path")}#{$style}.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('#{map-get($font,"path")}#{$style}.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }
  }
}

/* Font Awesome */
@import "~font-awesome/scss/font-awesome";

/* Material Icons */
@font-face {
  font-family: 'Material Icons';
  font-display: block;
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: inherit;  /* Preferred icon size */
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
