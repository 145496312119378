#preloader_2 {
  display: none;
  position: relative;
  left: 50%;
  width: 40px;
  height: 40px;
}
#preloader_2 span {
  display: block;
  bottom: 0;
  width: 30px;
  height: 30px;
  background: #9b59b6;
  position: absolute;
}
#preloader_2 span:nth-child(1) {
  -webkit-animation: preloader_1_1 1.5s infinite ease-in-out;
}
#preloader_2 span:nth-child(2) {
  left: 35px;
  -webkit-animation: preloader_1_2 1.5s infinite ease-in-out;
}

@-webkit-keyframes preloader_1_1 {
  0% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    border-radius: 0;
  }
  50% {
    -webkit-transform: translateX(40px) rotate(-180deg);
    border-radius: 20px;
    background: #3498db;
  }
  100% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(-360deg);
    border-radius: 0;
  }
}
@-webkit-keyframes preloader_1_2 {
  0% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
    border-radius: 0;
  }
  50% {
    -webkit-transform: translateX(-40px) rotate(180deg);
    border-radius: 20px;
    background: #f1c40f;
  }
  100% {
    -webkit-transform: translateX(0px) translateY(0px) rotate(360deg);
    border-radius: 0;
  }
}