//---------- THEME COLOR
$color_text:        #5D5D5D;
$color_text_bold:   #3D3D3D;
$color_text_light:  #707173;        // gris
$color_blue:        #005EA8;        // bleu
$color_green:       #005EA8;        // bleu
$color_gray:        #F7F7F7;
$color_border:      #C7C7C7;
$color_box_bg:      #FFFFFF;
$color_light_green: #20BC24;        // vert
$color_pink:        #20BC24;        // vert
$color_blue:        #007AD1;
$color_white:       #FFFFFF;
$color_supp:        #139FBD;
$color_logout:      #B33100;

$color_primary: #005EA8;
$color_primary_dark: #024C85;
$color_secondary: #5D5D5D;

$transition: .3s;

$path_fonts: '../fonts/';
$path_img: '../img/';

$font_material: 'Material Icons';
$font_awesome: 'FontAwesome';

$icomoon-font-family: "sld-font-icon" !default;
$icomoon-font-path: "#{$path_fonts}#{$icomoon-font-family}" !default;

$sld-icon-credit-card: "\e90e";
$sld-icon-delivery: "\e90f";
$sld-icon-map: "\e910";
$sld-icon-remove: "\e911";
$sld-icon-filter: "\e90a";
$sld-icon-menu: "\e90b";
$sld-icon-search: "\e90c";
$sld-icon-user: "\e90d";
$sld-icon-cart: "\e909";
$sld-icon-cookie: "\e908";
$sld-icon-facebook: "\e900";
$sld-icon-instagram: "\e901";
$sld-icon-linkedin: "\e902";
$sld-icon-pinterest: "\e903";
$sld-icon-rss: "\e904";
$sld-icon-twitter: "\e905";
$sld-icon-vimeo: "\e906";
$sld-icon-youtube: "\e907";


//---------- BOOTSTRAP
$grid-columns:      12;
$grid-gutter-width: 30px;
$grid-gutter-width-small: 20px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1220px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

$container-xs-max-width: 480px;

$border-radius: 0;

//---------- FONTS
$text-font:                         'Roboto', sans-serif;
$text-color:                        $color_text;

$title-font:                        'Roboto', sans-serif;
$title-color:                       $color_text;

$border-color:                      $color_border;
$border-color-lighten:              lighten($border-color, 15%);
$dark-border-color:                 #3b3b3b;
$light-border-color:                #ffffff;

$box-shadow-color:                  rgba(0, 0, 0, 0.2);

$link-color:                        $color_green;
$link-hover-color:                  $color_primary;

$box-background:                    $color_box_bg;
$box-background-darken:             darken($box-background, 8%);
$light-background:                  $color_white;
$box-padding:                       0.5rem; //0.9375rem;

$light-text-color:                  $color_white;

$wrapper-background:                #f7f7f7;

$center-enable-background:          false;
$center-background:                 #ffffff;
$center-border:                     none;
$center-padding:                    10px;

$column-block-background:           $color_box_bg;

$icon-color:                        $color_pink;

$header-background:                 #ffffff;
$breadcrumb-background:             #ffffff;

$footer-background:                 #ffffff;
$footer-text-color:                 inherit;
$footer-title-color:                $color_text_bold;


@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@function rem($pxValue) {
  $remValue: ($pxValue / 16) + rem;
  @return $remValue;
}

//---------- Bootstrap
$font-size-root:    16px;
$font-size-base:    1rem;
$line-height-base:  1.25;

$input-bg:            $color_white;
$input-color:         $text-color;
$input-border-color:  $border-color;
$input-color-placeholder: $color_text_light;
$input-focus-border-color: $border-color;
$input-btn-focus-box-shadow: none;

$btn-border-radius:       0;
$btn-border-radius-sm:    0;
$btn-border-radius-lg:    0;
$btn-line-height:         1.25rem;
$input-border-radius:     $border-radius;
$input-border-radius-sm:  $border-radius;
$input-border-radius-lg:  $border-radius;
$input-btn-border-width:  1px;
$input-padding-x:         0.75rem;
$input-padding-y:         0.375rem;
$input-line-height:       $line-height-base;
$input-height:            (($font-size-base * $line-height-base) + ($input-padding-y * 2));

$table-border-color:  $border-color;

$tooltip-color:         #fff;
$tooltip-bg:            #000;
$tooltip-padding-y:     0;
$tooltip-padding-x:     5px;
$tooltip-margin:        2px;
$tooltip-arrow-color:   $tooltip-bg;

$pre-color:               $text-color;
$blockquote-small-color:  $color_text_light;
$thumbnail-border-color:  $border-color;
$input-btn-border-width:  1px;

$primary:       $color_green;
$secondary:     $color_text_bold;

// not bootstrap
$btn-border-width:        1px;
$input-max-width:         200px;
$base-height:             (($line-height-base + (parseInt($input-padding-y) * 2)) * $font-size-root) + 2;
$base-padding:            parseInt($input-padding-y) * $font-size-root + 1;
$base-padding-border1px:  parseInt($input-padding-y) * $font-size-root;
$base-padding-border2px:  parseInt($input-padding-y) * $font-size-root - 1;

$custom-select-bg:        $input-bg;

$btn-primary-color:         $color_white;
$btn-primary-bg:            $color_green;
$btn-primary-border:        $color_green;
$btn-primary-color-hover:   $color_white;
$btn-primary-bg-hover:      darken($color_green, 10%);
$btn-primary-border-hover:      darken($color_green, 10%);

$btn-primary-outline-color:         $color_primary;
$btn-primary-outline-bg:            transparent;
$btn-primary-outline-border:        $color_primary;
$btn-primary-outline-color-hover:   #fff;
$btn-primary-outline-bg-hover:      $color_primary;
$btn-primary-outline-border-hover:  $color_primary;

$btn-secondary-color:       $color_white;
$btn-secondary-bg:          $color_text_bold;
$btn-secondary-border:      $color_text_bold;
$btn-secondary-color-hover: $color_white;
$btn-secondary-bg-hover:    darken($color_text_bold, 10%);
$btn-secondary-border-hover:darken($color_text_bold, 10%);

$btn-cart-color:            $color_white;
$btn-cart-bg:               $color_green;
$btn-cart-border:           $color_green;
$btn-cart-color-hover:      $color_white;
$btn-cart-bg-hover:         darken($color_green, 10%);
$btn-cart-border-hover:     darken($color_green, 10%);

/*
//----- XML SELECTORS
$border-color-lighten:              'lighten-border-color-15';
$box-background-darken:             'darken-box-background-8';
$btn-primary-bg-hover:              'darken-color-green-10';
$btn-cart-bg-hover:                 'darken-color-green-10';
$btn-secondary-bg-hover:            'darken-color-text-bold-10';

$title-color:                       #999999;
$icon-color:                        #888888;
$btn-primary-color:                 #777777;
$btn-primary-bg:                    #666666;
$btn-primary-bg-hover:              'darken-color-green-10';
$btn-cart-color:                    #777777;
$btn-cart-bg:                       #666666;
$btn-cart-bg-hover:                 'darken-color-green-10';
$primary:                           #666666;
*/


//---------- CLASSIC
$font-size-h1: 2rem;
$font-size-h2: 1.75rem;
$font-size-h3: 1.5rem;
$font-size-h4: 1.25rem;
$font-size-h5: 1rem;
$font-size-h6: 0.75rem;

$headings-font-weight: 700;

$extra-small-space: 10px;
$small-space: 20px;
$medium-space: 30px;
$large-space: 40px;
$extra-large-space: 50px;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.1875rem;
$display2-weight: 400;
$display3-size: 1.5625rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
