// header links
.sld-header-link {
  font-size: rem(16);
}
.header-sticky-desktop {
  padding-bottom: 10px;
}

.header-desktop {
  .left-nav-trigger {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    .left-nav-icon {
      width: 50px;
      height: 100%;
      text-align: center;
      background: $color_primary_dark;
      color: #fff;
      font-size: 30px;
      cursor: pointer;
      user-select: none;
      transition: $transition color, $transition background-color;
      &:hover {
        color: $color_primary;
        background-color: #fff;
      }
    }
  }
  .header-logo {
    max-width: 30%;
    z-index: 20;
    flex: none;
    font-family: $title-font;
    text-align: center;
    svg, img {
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 100px;
      vertical-align: middle;
      transition: all 0.3s;
    }
    @include media-breakpoint-only(md) {
      max-width: 40%;
    }
  }
  .header-right {
    width: 100%;
  }
}

.header-mobile {
  .main-header {
   display: flex;
    align-items: center;
    .header-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: initial;
      padding-left: 10px;
    }
    .header-logo-img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 150px;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    .sld-js-customer-toggle {
      border: none;
      background-color: transparent;
      color: $color_primary;
    }
    .sld-icon-user {
      font-size: 30px;
      line-height: 30px;
    }
    .shopping-cart-module {
      margin-right: 10px;
    }
    .cart-link {
      padding: 4px;
    }
  }
  .search-widget {
    .sld-icon-search {
      color: $color_primary;
    }
    .form-control {
      &::placeholder {
        color: $color_primary;
      }
    }
  }
  .header-bottom {
    position: relative;
    display: flex;
    margin-bottom: 5px;
    max-height: 43px;
    .left-nav-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 53px;
      height: 53px;
      position: relative;
      top: -5px;
      background-color: #024C85;
      color: #fff;
    }
    .sld-icon-menu {
      font-size: 30px;
    }
  }
}

.sld-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
}