.sld-history{
  display: flex;
  flex-direction: column;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  .sld-history-ctn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div{
      width: calc(45% / 2);
      padding: 1rem 0.5rem;
      &.sld-history-order-block1{
        width: 30%;
        display: flex;
        flex-direction: column;
      }
      &.sld-history-order-block2{
        width: 25%;
        span{
          padding: 0.25rem 0.1rem;
          color: #fff;
          white-space: nowrap;
          border-radius: 3px;
        }
      }
      &.sld-history-order-block3{
        .sld-history-order-block3-piece{
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          margin: 0.5rem 0;
          a{
            color: #5d5d5d;
          }
          i{
            font-size: 1.5rem;
          }
          div{
            font-size: 0.75rem;
            line-height: 1rem;
          }
        }
      }
    }
    &.sld-history-title{
      background-color: #ebebeb;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
    }
    &.sld-history-order{
      border-bottom: 1px solid #c7c7c7;

    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      > div{
        width: 100% !important;
        text-align: center;
        padding: 0.5rem 1rem;
        .sld-history-order-block3-piece{
          justify-content: center !important;
        }
      }
    }
  }
}
#cart {
  &:not(.touch-screen) {
    .cart-container-wrapper .sld-cart {
      position: relative;
      justify-content: flex-end;
    }
    .sld-product {
      display: flex;
    }
    .sld-product-grid-main-left {
      width: 70%;
      .sld-prices {
        margin-top: 10px;
      }
    }
    .product-name {
      margin-right: 10px;
    }
    .sld-product-grid-main-right {
      width: 30%;
      margin-top: 10px;
    }
    .sld-product-grid-new {
      .form-control {
        width: 34px;
      }
    }
    .product-quantity {
      margin-left: auto;
      max-width: 88px;
      .bootstrap-touchspin {
        margin-left: 0;
      }
      .btn-touchspin {
        width: 30px;
      }
    }
    .product-description-short {
      padding-right: 10px;
    }
    .sld-buylater {
      display: none;
    }
    .sld-product-price-total {
      padding-top: 6px;
    }
  }
  &.touch-screen {
    .sld-product-grid-1 {
      display: flex;
      flex-wrap: wrap;
      max-width: 80%;
      margin: 0 auto;
      .product-name {
        display: block;
        line-height: 14px;
      }
    }
    .sld-product-grid-2 {
      padding: 0 0 20px;
    }
    .sld-product-grid-main-left {
      padding-bottom: 10px;
    }
    .sld-product-grid-6 {
      padding-top: 0;
      width: initial;
    }
    .sld-buylater {
      display: none;
    }
    .sld-prices{
      margin-bottom: 10px;
    }
  }
  .remove-from-cart {
    display: block;
    background-color: $color_primary;
    color: #fff;
    border: 1px solid $color_primary;
    padding: 6px;
    width: 34px;
    height: 34px;
    &:hover {
      background-color: #fff;
      color: $color_primary;
    }
    i {
      font-size: 20px;
    }
  }
}

#checkout {
  #outstanding_asialand_row_text_alert {
    width: 100%;
    max-width: 100%;
    flex:  0 0 100%;
    .warning-outstanding {
      padding: 6px 20px;
      background-color: rgba(#ff0000, .06);
      border: none;
      text-align: left;
    }
  }
}
