.category-title-container {
  max-width: calc(100% - 110px);
}
.category-description {
  .small-description {
    overflow: hidden;
    position: relative;
  }
  .description-expand {
    margin-top: 15px;
    display: none;
  }
  .small-description.see-more {
    max-height: 100px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 35px;
      background-image: linear-gradient(to top, rgba(247, 247, 247, 0) 0, rgba(247, 247, 247, 0.8) 40%, #f7f7f7 90%);
    }
    + .description-expand {
      display: block;
    }
  }
}
.subcategory-miniature {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .subcategory-description {
    display: none;
  }
  .subcategory-name {
    margin-top: 10px;
    font-size: 1em;
    text-align: center;
  }
  .subcategory-image {
    img {
      border-radius: 3px;
    }
  }
  &.no-image {
    .subcategory-name {
      margin-top: auto;
    }
  }
}
.total-products {
  color: $color_supp;
  font-weight: 600;
  font-size: rem(14);
  line-height: 1;
  margin-bottom: 15px;
  p {
    margin-bottom: 0;
  }
}
.products-selection {
  display: flex;
  justify-content: space-between;
  .total-products {
    padding-top: $base-padding;
    p {
      margin-bottom: $base-padding;
      color: $color_supp;
    }
  }
  .products-sort-order {
    .sort-label {
      margin-right: 10px;
    }
    .sort-select {
      width: 100%;
      max-width: $input-max-width;
    }
    .select-title {
      cursor: pointer;
      min-height: $base-height;
      white-space: nowrap;
    }
    .dropdown-menu {
      width: 100%;
    }
    .product-display {
      margin-left: auto;
      .display-label {
        margin-right: 10px;
      }
      .display-select {
        font-size: ($line-height-base*$font-size-root) + 2px;
        margin: 0;
        background: $light-background;
        padding: $base-padding-border2px 5px;
        border: 1px solid $border-color;
        border-radius: 5px;
        a {
          margin: 0 2px;
          .material-icons, .fa {
            margin-top: 0;
          }
        }
        .selected {
          color: $link-color;
        }
        .material-icons {
          display: block;
        }
      }
    }
  }
  .filter-button {
    #search_filter_toggler {
      width: 100%;
    }
  }
}

#search_filters {
  .facet {
    margin-top: 20px;
    + .facet {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px dashed $border-color;
    }
    .collapse {
      display: block;
      padding-left: 5px;
    }
    .facet-title {
      margin-bottom: 0;
      font-size: 0.875rem;
    }
    .facet-label {
      padding: $base-padding 0;
      &.active {
        .custom-checkbox input[type="checkbox"]:checked + span.color {
          margin-left: -3px;
        }
      }
    }
    .facet-dropdown {
      margin-top: $base-padding;
      .dropdown-icon {
        margin-left: auto;
      }
      .dropdown-menu {
        width: 100%;
      }
      .select-title {
        padding: $base-padding;
        background: $color_gray;
        border-radius: 3px;
      }
    }
    .magnitude {
      padding-left: 3px;
    }
  }
}

.pagination-wrapper {
}
.pagination {
  width: 100%;
  margin: 0;
  display: block;
  .page-total {
    font-weight: 700;
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }
  .row {
    align-items: center;
  }
  .page-list {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
      &.nav-only {
          justify-content: center;
      }
    .item {
      border: 1px solid $color_primary;
      margin: 0 2px;
    }
    .prev,
    .next {
      a {
        border: 1px solid transparent;
        &:hover {
          border-color: $color_primary;
        }
        @include media-breakpoint-down(sm) {
          span {
            display: none;
          }
        }
      }
    }
    a {
      padding: 5px;
      font-weight: 500;
      display: block;
      color: $color_primary;
      text-align: center;
      min-width: 30px;
      &:hover {
        background-color: $color_primary;
        color: #fff;
      }
      .material-icons {
        font-size: 18px;
        margin-top: 0;
      }
    }
    .current a {
      background-color: $link-color;
      color: $light-text-color;
    }
    .previous {
      padding-left: 0;
      font-size: 90%;
    }
    .next {
      padding-right: 0px;
      font-size: 90%;
    }
  }
}

.active-filters {
  padding: 1rem 1rem 0.5rem;
  margin-top: -12px;
  .active-search-wrapper {
    display: flex;
    align-items: center;
  }
  .active-filter-title {
    margin-right: 8px;
    text-transform: uppercase;
  }
  .active-filter-list {
  }
  .filter-block {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    background: $box-background-darken;
    padding: 2px 8px;
    display: inline-block;
    .material-icons {
      color: $icon-color;
      font-size: 14px;
      margin-top: -1px;
      margin-left: 3px;
    }
  }
}

.left-categories {
  .column-title {
    margin-bottom: 12px;
  }
  
  .arrows {
    .arrow-right,
    .arrow-down {
      font-size: $font-size-sm;
      cursor: pointer;
      margin-left: 2px;
      &:hover {
        color: $primary;
      }
    }
    .arrow-down {
      display: none;
    }
    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }
      .arrow-down {
        display: inline-block;
      }
    }
  }
}
.category-tree {
  ul {
    margin-bottom: 0;
    li {
      position: relative;
    }
  }
  .collapse-icons {
    position: absolute;
    right: -5px;
    top: $base-padding;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
    text-align: center;
    width: ($line-height-base * $font-size-root);
    height: ($line-height-base * $font-size-root);
    line-height: (($line-height-base * $font-size-root) - 1);
    border: none;
    user-select: none;
    &[aria-expanded="true"] {
      .add {
        display: none;
      }
      .remove {
        display: inline-block;
      }
    }
    .add,
    .remove {
      font-weight: 700;
      margin-top: -1px;
    }
    .remove {
      display: none;
    }
  }
  
  > ul > li {
    padding: $base-padding 0;
    > a {
      font-size: 1.1em;
      margin-right: 8px;
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border: 1px solid $border-color;
        border-radius: 50%;
        display: inline-block;
      }
      &:hover:before {
        border-color: $link-hover-color;
        background-color: $link-hover-color;
      }
      img {
        max-height: 33px;
        margin: -8px 3px -6px -21px;
      }
      @include media-breakpoint-only(md) {
        .left-categories & {
          font-size: 1em;
        }
      }
    }
  }
  .category-sub-menu {
    border-left: 1px solid $border-color;
    border-right: 0px solid $border-color;
    margin-top: 5px;
    margin-left: 15px;
    .category-sub-menu {
      margin-left: 0;
    }
    > ul > li {
      padding: 3px 0 3px 10px;
      .collapse-icons {
        right: 0;
        top: 3px;
      }
    }
  }
  a.current {
    font-weight: 700;
  }
}

.manufacturer-list {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .brand {
    margin-bottom: 20px;
    .brand-container {
    }
    .brand-img {
      text-align: center;
      img {
        border: none;
        padding: 0;
      }
    }
    .brand-name {
      font-size: 0.875rem;
      font-family: $text-font;
      text-align: center;
      margin: 15px -15px;
      padding: 8px 0;
      background: $color_gray;
    }
    .brand-products {
      display: flex;
      justify-content: space-around;
    }
    .brand-desc {
      width: 100%;
      display: none;
    }
  }
}

/*** Responsive part ***/
.mobile-search-fillter {
  display: none;
  margin-top: -10px;
  #search_filter_controls {
    text-align: center;
    button {
      margin: 2px 3%;
      @include media-breakpoint-down(sm) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  #search_filters {
    .facet {
      margin-top: 0;
      padding-top: 0;
      .title {
        cursor: pointer;
        padding: $base-padding 0;
        .collapse-icons .remove {
          display: none;
        }
      }
      .title[aria-expanded="true"] {
        .collapse-icons {
          .add {
            display: none;
          }
          .remove {
            display: block;
          }
        }
      }
      .facet-title {
        text-transform: uppercase;
        display: inline-block;
      }
      .navbar-toggler {
        display: block;
        float: right;
        padding: 0;
        border: none;
        font-size: ($line-height-base + rem);
      }
      .collapse {
        display: none;
        &.show {
          display: block;
        }
      }
      .facet-label {
        a {
          margin-top: 0;
        }
      }
      ul {
        margin-bottom: 0;
        padding: 0;
        li {
          padding-left: $base-padding;
          &:last-child {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
#module-pm_advancedsearch4-searchresults,
#category {
  h2.h2,
  h1.page-heading {
    line-height: inherit;
    padding: 0;
    color: #5D5D5D;
    margin-right: 10px;
  }
}
@include media-breakpoint-up(lg) {
  .product-list .product-miniature .product-thumbnail img {
    max-height: 110px;
    width: auto;
  }
}