.searchbar-wrapper {
  width: 100%;
}
.search-widget {
  width: 70%;
//  margin: auto;
  @include media-breakpoint-up(lg) {
    margin-left: 7vw;
  }
  @include media-breakpoint-down(lg) {
    width: auto;
  }
  @include media-breakpoint-down(sm) {
//    width: 80%;
  }
  form {
    position: relative;
    input[type="text"] {
      height: 43px;
      padding-right: 5%;
      padding-left: 53px;
      border-width: 0 0 2px 0;
      border-style: solid;
      border-color: transparent;
      background-color: #f7f7f7;
      transition: $transition border-bottom-color;
      &:focus {
        border-color: $color_primary;
      }
    }
    [type="submit"] {
      border: none;
      display: block;
      padding: 0;
      position: absolute;
      top: 0;
      left : 0;
      width: 43px;
      height: 43px;
      cursor: pointer;
      background-color: transparent;
      transition: $transition color, $transition background-color;
      color: $color_primary;
      &:hover {
        color: #fff;
        background-color: $color_primary;
      }
      .sld-icon-search {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
      @include media-breakpoint-down(sm) {
        background: transparent;
        color: inherit;
      }
    }
  }
}
#products .search-widget {
  margin: auto;
}
.sidebar-search {
  padding: 20px;
  border-top: 1px solid $border-color;
  .search-widget {
    width: 100%;
  }
}

.ui-autocomplete.ui-menu {
  .search-menu-item {
    list-style: none;
    .search-item {
      display: flex;
      align-items: center;
      border-radius: 0;
      .info {
        padding-left: 3%;
        .category, .separator {
          display: none;
        }
      }
      .cover {
        margin-right: 2%;
        min-width: 50px;
        max-width: 50px;
        img {
          border: 1px solid $border-color-lighten;
        }
        + .info {
          padding-left: 0;
        }
      }
      .pprice {
        font-weight: 700;
      }
      .pref {
        font-style: italic;
        display: none;
      }
    }
  }
}

@include media-breakpoint-only(md) {
  .searchbar-wrapper {
    margin-bottom: 15px;
  }
  .search-widget {
    margin-right: 0;
  }
}